import { initReactI18next } from "react-i18next";

import i18next from "i18next";

import translation from "./en/translation.json";
import terjemahan from "./my/translation.json";
import translationCN from "./cn/translation.json";

i18next.use(initReactI18next).init({
  lng:
    localStorage.getItem("language") &&
    localStorage.getItem("language") === "cn"
      ? "cn"
      : localStorage.getItem("language") === "my"
      ? "my"
      : "en",
  debug: true,
  resources: {
    en: {
      translation,
    },
    my: {
      translation: terjemahan,
    },
    cn: {
      translation: translationCN,
    },
  },
  // if we see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
});
