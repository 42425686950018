import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import "./i18n/config";
import "./App.css";

const Loading = lazy(() => import("./components/Loading.js"));
const Login = lazy(() => import("./pages/Login.js"));
const Verify = lazy(() => import("./pages/Verify.js"));
const Profile = lazy(() => import("./pages/Profile/Profile.js"));
const Parents = lazy(() => import("./pages/Profile/Parents.js"));
const ProfileChild = lazy(() => import("./pages/Profile/Child.js"));
const ProfileAddress = lazy(() => import("./pages/Profile/Address.js"));
const Personal = lazy(() => import("./pages/Registration/Personal.js"));
const Child = lazy(() => import("./pages/Registration/Child.js"));
const Address = lazy(() => import("./pages/Registration/Address.js"));
const Complete = lazy(() => import("./pages/Registration/Complete.js"));
const Rewards = lazy(() => import("./pages/Rewards/Rewards.js"));
const Badges = lazy(() => import("./pages/Badges/Badges.js"));
const UploadReceipt = lazy(() => import("./pages/UploadReceipt/Upload.js"));
const HomePage = lazy(() => import("./pages/Home/HomePage.js"));
const History = lazy(() => import("./pages/History/History.js"));
const MySubmission = lazy(() => import("./pages/History/MySubmission.js"));
const PointHistory = lazy(() => import("./pages/History/PointHistory.js"));
const PointShopPage = lazy(() => import("./pages/PointShop/PointShopPage.js"));
const PointShopInfoPage = lazy(() =>
  import("./pages/PointShop/PointShopInfoPage.js")
);
const Cart = lazy(() => import("./pages/PointShop/Cart.js"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/loading" element={<Loading />} />
            <Route path="/" element={<Login />} />
            <Route path="/verify" element={<Verify />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/parents" element={<Parents />} />
            <Route path="/profile/child" element={<ProfileChild />} />
            <Route path="/profile/address" element={<ProfileAddress />} />

            <Route path="/registration" element={<Personal />} />
            <Route path="/registration/child" element={<Child />} />
            <Route path="/registration/address" element={<Address />} />
            <Route path="/registration/complete" element={<Complete />} />

            <Route path="/rewards" element={<Rewards />} />
            <Route path="/badges" element={<Badges />} />
            <Route path="/upload" element={<UploadReceipt />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/history" element={<History />}>
              <Route path="submission" element={<MySubmission />} />
              <Route path="point" element={<PointHistory />} />
            </Route>
            <Route path="/pointShop" element={<PointShopPage />} />
            <Route path="/pointShop/info" element={<PointShopInfoPage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
